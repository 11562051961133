import {html} from '@polymer/polymer/lib/utils/html-tag.js';
import {css} from 'lit';
const styles = css`
:host {
    display: block;

    --material-font-family: 'Source Sans Pro', sans-serif;

    --paper-input-container: {
        --paper-input-container-color: hsl(0, 0%, 58%);
        background-color: var(--tcg-input-container-background-color);
        border-radius: var(--tcg-input-container-border-radius);
        padding-bottom:0;
        padding-left: 12px;
        padding-right: 12px;
    }

    --paper-input-container-underline: {
        /*
        undo padding-left and padding-right of --paper-input-container
        this makes sure that the underline runs across the whole input container
         */
        margin-left: -12px;
        margin-right: -12px;
    }

    --paper-input-container-underline-focus: {
        /*
        undo padding-left and padding-right of --paper-input-container
        this makes sure that the underline runs across the whole input container
         */
        margin-left: -12px;
        margin-right: -12px;
    }

    --iron-autogrow-textarea: {
        padding:0; /* Browser stylesheet defaults to 2px padding, makes this misalign with the label when using paper-textarea. */
    }

    --tcg-subtitle-2: {
        font-size: 14px;
        color: rgba(0,0,0,.54);
    }

    /* ################################### */

    --accent-color: var(--tcg-accent-color);

    --tcg-list-bottom-margin: 80px;
    --tcg-content-max-width: 1400px;
    --tcg-list-padding: 24px;
    --tcg-input-padding-bottom: 24px;
    --icon-button-color: rgba(0,0,0, var(--dark-secondary-opacity));
    --tcg-input-container-background-color: hsl(0, 0%, 96%);
    --tcg-input-container-border-radius: 4px 4px 0 0;
    --tcg-minerva-green: rgb(102, 153, 102); /* #669966 */
    --tcg-minerva-green-background: rgba(102, 153, 102, 0.2);
    --tcg-minerva-green-dark: rgb(72, 109, 72); /* #486D48 */
    --tcg-minerva-green-light: rgb(120, 205, 171); /* #78CDAB */
    --tcg-minerva-red: rgb(204, 102, 102); /* #CC6666 */
    --tcg-minerva-red-background: rgba(204, 102, 102, 0.2);
    --tcg-minerva-red-dark: rgb(153, 51, 51); /* #993333 */
    --tcg-minerva-orange: rgb(204, 153, 0); /* #CC9900 */
    --tcg-minerva-orange-background: rgba(204, 153, 0, 0.2);
    --tcg-minerva-orange-dark: rgb(188, 134, 0); /* #BC8600 */
    --tcg-black-primary-opacity: rgba(0, 0, 0, var(--dark-primary-opacity));
    --tcg-minerva-gray: rgba(0, 0, 0, 0.04);
    --sdb-primary-button: #f49d10;
    --sdb-theme-primary-text: var(--tcg-primary-color-onprimary);
    --mdc-theme-primary: var(--tcg-primary-background-color);
    --mdc-theme-secondary: var(--tcg-secondary-background-color);
    --mdc-theme-on-secondary: var(--tcg-primary-color-onsecondary);
    --warning-color: var(--sdb-warning-color, #face88);
    --alert-color: var(--sdb-alert-color, #fa8b88);
    --success-color: var(--sdb-success-color, #80d8d5);
    --inprogress-color: var(--sdb-inprogress-color, #2196F3);
    --element-lightgrey: #eaeaea;

    /* HEADER */
    --header-primary: var(--sdb-header-primary, #00b1aa);
    --header-secondary: var(--sdb-header-primary, #136f8b);
    --header-tertiary: var(--sdb-header-primary, #b2b2b2);
    /* TEXT */
    font-family: var(--font-family);
    /* BUTTONS */
    --paper-toggle-button-checked-bar-color: var(--btn-primary);
    --paper-toggle-button-checked-button-color: var(--btn-primary);
    --paper-toggle-button-checked-ink-color: var(--btn-primary);

    --paper-tabs-selection-bar-color: var(--btn-primary);

    --paper-badge-background: var(--btn-primary);
    --paper-badge-text-color: var(--btn-primary-text);

    /* ################################ */
}

/* APP-DRAWER & FILTERSHEET STYLING */

app-drawer{
    position:fixed;
    top:140px;
    height: calc(100vh - 140px);
    margin:0;
    --app-drawer-content-padding: 0;
    --app-drawer-content-container: {
        background: white !important;
    }
    margin-top:0 !important;
    box-shadow: -1px 0px 10px 1px rgba(0,0,0,0.12);
}

@media (max-width: 767px) {
    app-drawer {
        top:70px;
        height: calc(100vh - 70px);
    }
}

#filterSheet{
    padding:15px 15px 0;
    text-align: left;
}

/* ################################ */

.tcg-subtitle-1-black {
    font-size: 16px;
    color: rgba(0,0,0,var(--light-secondary-opacity)); /* black 0.7 */
}

.tcg-subtitle-2-black {
    font-size: 14px;
    color: rgba(0,0,0,var(--light-secondary-opacity)); /* black 0.7 */
}
.tcg-H5-white {
    @apply --tcg-H5-white;
}
.tcg-H5-black {
    font-size: 24px;
    color: rgba(0,0,0,var(--dark-primary-opacity)); /* black 0.87 */
}

.tcg-body1-black {
    font-size: 16px;
    color: rgba(0,0,0,var(--dark-primary-opacity)); /* black 0.87 */
}

.tcg-body2-black {
    font-size: 14px;
    color: rgba(0,0,0,var(--dark-secondary-opacity)); /* black 0.54 */
}

.tcg-opacity-054 {
    opacity: var(--dark-secondary-opacity);
}

.tcg-opacity-087 {
    opacity: var(--dark-primary-opacity);
}


vaadin-select {
    background-color: var(--tcg-input-container-background-color);
    border-radius: var(--tcg-input-container-border-radius);
    border-bottom: -8px; /* fix the margin-bottom of the inner element */
}

vaadin-select[transparent] {
    background-color: transparent;
}

paper-icon-item paper-icon-button:not([disabled]),
paper-item paper-icon-button:not([disabled]),
paper-menu-button paper-button:not([disabled]) {
    color: var(--icon-button-color);
}

paper-button:not([disabled]):not(.primary):not(.contained) {
    color: var(--tcg-primary-background-color);
}

paper-button {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
}

paper-button.primary, paper-button.contained {
    background-color: var(--sdb-button-primary);
    color: var(--sdb-button-primary-text);
}

paper-badge iron-icon{
    color: white;
}
a {
    text-decoration: none;
    color: var(--sdb-button-primary);
}

app-header {
    background-color: var(--tcg-primary-background-color-dark);
    color: var(--tcg-primary-color-onprimary);
}

.header {
    background-color: var(--tcg-primary-background-color);
    color: var(--tcg-primary-color-onprimary);
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 2rem 0 2rem;
}
.nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

paper-card {
    flex-grow: 1;
    background: white;
}

.with-href {
    cursor: pointer;
}

paper-input {
    --paper-input-container_-_background-color: var(--tcg-input-container-background-color);
    --paper-input-container_-_--paper-input-container-color: hsl(0, 0%, 58%);
    --paper-input-container_-_border-radius: var(--tcg-input-container-border-radius);
}

paper-input[transparent] {
    --paper-input-container_-_background-color: transparent;
}

paper-input[readonly] {
    --paper-input-container-underline: {
        display: none;
    }
}

paper-item-body[two-line] {
    height: 71px; /* IE 11 fix for min-height: 72px; */
}
paper-item-body{
    margin-left:16px;
}

.badge-container {
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
  }
  /* Need to position the badge to look like a text superscript */
  .badge-container > paper-badge {
    --paper-badge-margin-left: 20px;
    --paper-badge-margin-bottom: 0px;
    --paper-badge-text-color: var(--sdb-button-primary-text);
  }

paper-toast {
    text-align: center;
}

.filterContent {
    background: #fff;
    height: 100%;
}

.card-header__iron-icon {
    padding: 0.6rem;
    border-radius: 50%;
    background: #ddd; /* TODO: choose TCG color */

    margin-right: 1rem;
}

.card-content__paper-listbox {
    margin-top: -1rem;
}

.form-input-width {
    width: calc(50% - 12px);
}

@media (max-width: 640px) {
    .form-input-width {
        width: 100%;
    }
}

/* dialog start */
.dialog-content {
    padding: 0 2rem 0 2rem;
}

.dialog-content-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dialog-content-footer__buttons {
    margin-right: 3rem;
}
/* dialog end */

.page-info {
    background-color: var(--tcg-primary-background-color);
    color: var(--tcg-primary-color-onprimary);
    width: 100%;
    box-sizing: border-box;
    padding: 2rem;
}

.page-info a {
    color: var(--tcg-primary-color-onprimary);
}

.grid-buttons {
    display: flex;
}

#content {
    box-sizing: border-box;
    @apply --layout-horizontal;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    padding: 0;
    width: 100%;
}

.full-width {
    width: 100%;
    flex-basis: auto;
    max-width: none;
}

*[hidden] {
    display: none;
}

/* TODO: remove both 50% because it is not centering */
paper-spinner {
    position: fixed;
    left: 50%;
    top: 50%;
    display:none;
}
paper-spinner[active] {
    display:inline-block;
}

paper-item-body {
    border-bottom: 1px solid rgba(0,0,0, var(--dark-divider-opacity));
}

paper-fab-speed-dial {
    --paper-fab-speed-dial-background: var(--btn-primary, var(--accent-color));
    --text-primary-color: var(--tcg-primary-color-onsecondary);
}

paper-fab {
    --text-primary-color: var(--tcg-primary-color-onsecondary);
    --paper-fab-background: var(--btn-primary, var(--accent-color));
}

sdb-content-button:not([secondary]) {
    --text-light: var(--sdb-button-primary-text);
}

md-fab {
    position: fixed;
    right: 16px;
    bottom: 16px;
    --md-ref-typeface-plain: var(--font-family);
    --md-sys-color-primary: var(--sdb-button-primary-text);
    --md-sys-color-surface-container-high: var(--sdb-button-primary);
}

paper-fab-speed-dial {
    position: fixed;
    right: 0px;
    bottom: 0px;

    --paper-fab-speed-dial-keyboard-focus-background: var(--btn-primary);
    --text-primary-color: var(--sdb-button-primary-text);
}

paper-fab-speed-dial-action {
    cursor: pointer;
}

paper-fab {
    position: fixed;
    bottom: 16px;
    right: 16px;
}

#toastDiv {
    height: 70px;
    position: fixed;
    bottom: 0;
}

@media (max-width: 640px) {
    :host {
        --tcg-list-padding: 0;
    }
}

@media (min-width: 1280px) {
    #content {
        padding: 1rem 2rem 1rem 2rem;
    }

    #drawer-toggle {
        display: none;
    }
}

.titleText {
    font-size: 24px;
    opacity: 0.87;
    margin: 10px;
}

.body1Text {
    font-size: 16px;
    opacity: 0.6;
    margin: 10px;
}

.avatar {
    display: inline-block;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    text-align: center;
}

.group-icon-background {
    background: #e0e0e0;
}

.group-photo-icons {
    color: rgba(103, 103, 103, 1);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.filterHeaderBody2TextWithPadding {
    padding-top: 20px;
    font-size: 16px;
    opacity: 0.6;
}

.heading {
    color:rgba(0,0,0, .54);
    font-size: 14px;
}

wl-button {
    --button-letter-spacing: 0.078125rem;
    font-weight: 500;
    --button-font-size: 14px;
    border-radius: 4px;
    padding: 11px 16px;
    --font-family-sans-serif: 'Source Sans Pro', sans-serif;
}

wl-banner {
    padding: 16px;
    --banner-content-padding: 0;
}

/* ######################### */
/* Styling for FAB movement: */
/* ######################### */
@keyframes moveLeft {
    from {margin-right: 0px;}
    to {margin-right: 350px;}
}

@keyframes moveRight {
    from {margin-right: 350px;}
    to {margin-right: 0px;}
}
.fabPosition {
    transition: margin-right 0.2s;
    margin-right: 350px;
}

:host([toggle-filter]) .fabPosition,
:host([togglefilter]) .fabPosition,
:host([filterOpened]) .fabPosition {
    /* LitElement translates toggleFilter to togglefilter instead of toggle-filter :( */
    margin-right: 0px;
}
@media(max-width:600px) {
    .fabPosition {
        margin-right: 0px;
    }
}
.slot-div{
    display: inline-block;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    text-align: center;
    background: #666666;
}
.slot-icon-inside-div{
    color: rgb(255, 255, 255);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.chip-close-icon{
    cursor:pointer;
    --iron-icon-height: 16px;
    --iron-icon-width: 16px;
    position: relative;
    right: 0;
    color: var(--paper-chip-background-color, #E0E0E0);
    background-color: var(--paper-chip-close-color, #A6A6A6);
    border-radius: 50%;
    top: -1px;
}
.toast-error {
    --paper-toast-background-color: #d9534f;
    border-color: #d43f3a;
    --paper-toast-color: white;
}
/* ######################### */

`;

export default styles;

const inheritedStyles = document.createElement('template');

inheritedStyles.innerHTML = styles.cssText;

const template = html`
    <dom-module id="tcg-shared-styles">
        <template>
            <style>

                ${inheritedStyles}
            </style>
        </template>
    </dom-module>

    <dom-module
        id="vaadin-tcg-theme"
        theme-for="vaadin-*"
    >
        <template>
            <style>
                [part='text-field'] {
                    background-color: hsl(0, 0%, 96%);
                    border-radius: 4px 4px 0 0;
                    padding-bottom: 0;
                }

                [readonly][part='text-field'] {
                    background-color: white;
                }

                [part='label'] {
                    margin: 0 12px 0 12px;
                }

                [part='value'] {
                    margin: 0 12px 0 12px !important;
                }

                [part='primary-buttons'] {
                    color: var(--tcg-primary-color-onsecondary, #000);
                    --material-body-text-color: var(--tcg-primary-color-onsecondary, #000);
                }

                vaadin-time-picker-text-field {
                    background-color: var(--tcg-input-container-background-color);
                    border-radius: var(--tcg-input-container-border-radius);
                    padding-bottom: 0;
                }

                vaadin-select-text-field {
                    margin-bottom: 0;
                }
            </style>
        </template>
    </dom-module>
    <dom-module
        id="vaadin-tcg-theme-combo-box-item"
        theme-for="vaadin-combo-box-item"
    >
        <template>
            <style>
                [part='content'] {
                    width: calc(100% - 26px);
                }
            </style>
        </template>
    </dom-module>
`;

document.head.appendChild(template.content);
